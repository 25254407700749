<template>
    <svg width="232" height="46" viewBox="0 0 232 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M45 23C45 35.7025 34.9264 46 22.5 46C10.0736 46 0 35.7025 0 23C0 10.2975 10.0736 0 22.5 0C34.9264 0 45 10.2975 45 23Z"
            fill="#FFED00" />
        <path
            d="M15.5025 38.0785H15.5784C15.797 38.0785 15.944 37.8542 15.8446 37.6596C15.1 36.2006 14.0591 35.6535 11.6768 35.2638C11.5429 35.2419 11.4111 35.2044 11.2876 35.1481C9.54932 34.3558 8.8946 33.6314 8.09206 32.3911C7.95567 32.1803 7.87753 31.9366 7.85106 31.6869C7.63396 29.6395 7.0787 28.7245 5.65996 27.8605C5.49055 27.7573 5.27748 27.8824 5.27748 28.0807C5.27748 30.9334 5.22533 34.1718 7.30392 36.1256C7.45358 36.2663 7.61303 36.3997 7.78367 36.5268C9.92924 38.1246 12.8274 38.0785 15.5025 38.0785Z"
            fill="#212123" />
        <path
            d="M29.7324 7.92201H29.6565C29.438 7.92201 29.2909 8.14628 29.3903 8.34096C30.135 9.7999 31.1758 10.347 33.5581 10.7367C33.692 10.7586 33.8238 10.7962 33.9473 10.8524C35.6856 11.6447 36.3403 12.3692 37.1429 13.6095C37.2793 13.8202 37.3574 14.064 37.3839 14.3136C37.601 16.361 38.1562 17.2761 39.575 18.14C39.7444 18.2432 39.9574 18.1181 39.9574 17.9198C39.9574 15.0671 40.0096 11.8287 37.931 9.87494C37.7813 9.73426 37.6219 9.60077 37.4513 9.47369C35.3057 7.87587 32.4076 7.92201 29.7324 7.92201Z"
            fill="#212123" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.9372 27.9338C22.0133 27.9338 25.9913 23.9628 25.9913 19.2307C25.9913 14.4986 22.0133 10.5276 16.9372 10.5276C11.8612 10.5276 7.88316 14.4986 7.88316 19.2307C7.88316 23.9628 11.8612 27.9338 16.9372 27.9338ZM16.9372 30.5393C23.3767 30.5393 28.5969 25.4763 28.5969 19.2307C28.5969 12.9851 23.3767 7.922 16.9372 7.922C10.4978 7.922 5.27759 12.9851 5.27759 19.2307C5.27759 25.4763 10.4978 30.5393 16.9372 30.5393Z"
            fill="#020202" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M28.2978 35.4729C33.3739 35.4729 37.3519 31.5019 37.3519 26.7698C37.3519 22.0377 33.3739 18.0667 28.2978 18.0667C23.2218 18.0667 19.2438 22.0377 19.2438 26.7698C19.2438 31.5019 23.2218 35.4729 28.2978 35.4729ZM28.2978 38.0785C34.7373 38.0785 39.9575 33.0154 39.9575 26.7698C39.9575 20.5242 34.7373 15.4611 28.2978 15.4611C21.8584 15.4611 16.6382 20.5242 16.6382 26.7698C16.6382 33.0154 21.8584 38.0785 28.2978 38.0785Z"
            fill="#020202" />
        <path
            d="M16.6382 15.7511V27.3497L19.6278 20.3906V13.4314H18.9579C17.6768 13.4314 16.6382 14.4699 16.6382 15.7511Z"
            fill="#212123" />
        <path
            d="M28.5969 30.5393V18.9407L25.6072 25.8999V32.8591H26.2771C27.5583 32.8591 28.5969 31.8205 28.5969 30.5393Z"
            fill="#212123" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M61 30.6818C64.2133 30.6818 66.8182 27.9141 66.8182 24.5C66.8182 21.0859 64.2133 18.3182 61 18.3182C57.7867 18.3182 55.1818 21.0859 55.1818 24.5C55.1818 27.9141 57.7867 30.6818 61 30.6818ZM61 33C65.4183 33 69 29.1944 69 24.5C69 19.8056 65.4183 16 61 16C56.5817 16 53 19.8056 53 24.5C53 29.1944 56.5817 33 61 33Z"
            fill="#212123" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M129 30.6818C132.213 30.6818 134.818 27.9141 134.818 24.5C134.818 21.0859 132.213 18.3182 129 18.3182C125.787 18.3182 123.182 21.0859 123.182 24.5C123.182 27.9141 125.787 30.6818 129 30.6818ZM129 33C133.418 33 137 29.1944 137 24.5C137 19.8056 133.418 16 129 16C124.582 16 121 19.8056 121 24.5C121 29.1944 124.582 33 129 33Z"
            fill="#212123" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M184 30.6818C187.213 30.6818 189.818 27.9141 189.818 24.5C189.818 21.0859 187.213 18.3182 184 18.3182C180.787 18.3182 178.182 21.0859 178.182 24.5C178.182 27.9141 180.787 30.6818 184 30.6818ZM184 33C188.418 33 192 29.1944 192 24.5C192 19.8056 188.418 16 184 16C179.582 16 176 19.8056 176 24.5C176 29.1944 179.582 33 184 33Z"
            fill="#212123" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M164.5 30.6818C167.914 30.6818 170.682 27.9141 170.682 24.5C170.682 21.0859 167.914 18.3182 164.5 18.3182C161.086 18.3182 158.318 21.0859 158.318 24.5C158.318 27.9141 161.086 30.6818 164.5 30.6818ZM164.5 33C169.194 33 173 29.1944 173 24.5C173 19.8056 169.194 16 164.5 16C159.806 16 156 19.8056 156 24.5C156 29.1944 159.806 33 164.5 33Z"
            fill="#212123" />
        <path d="M160 15V12H166.5L160 15Z" fill="#212123" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M109.5 30.6818C112.914 30.6818 115.682 27.9141 115.682 24.5C115.682 21.0859 112.914 18.3182 109.5 18.3182C106.086 18.3182 103.318 21.0859 103.318 24.5C103.318 27.9141 106.086 30.6818 109.5 30.6818ZM109.5 33C114.194 33 118 29.1944 118 24.5C118 19.8056 114.194 16 109.5 16C104.806 16 101 19.8056 101 24.5C101 29.1944 104.806 33 109.5 33Z"
            fill="#212123" />
        <path d="M67 16H69V33H67V16Z" fill="#212123" />
        <path d="M135 16H137V33H135V16Z" fill="#212123" />
        <path d="M190 16H192V33H190V16Z" fill="#212123" />
        <path d="M156 12H158V33H156V12Z" fill="#212123" />
        <path d="M198 16H200V33H198V16Z" fill="#212123" />
        <path d="M210 16H212V33H210V16Z" fill="#212123" />
        <path d="M218 16H220V33H218V16Z" fill="#212123" />
        <path d="M227.798 16.4021L230.697 16.4021L222.515 25.0573L220.896 23.4781L227.798 16.4021Z" fill="#212123" />
        <path d="M228.897 32.7626L231.867 32.835L223.269 23.7537L221.273 24.8846L228.897 32.7626Z" fill="#212123" />
        <path d="M101 16H103V37H101V16Z" fill="#212123" />
        <path
            d="M78.209 17L72 33H75.1045L79.3731 21.5714L84.4179 33H85.9701L91.0149 21.5714L95.2836 33H98L91.791 17H89.8507L85.5821 28.4286H84.806L80.1493 17H78.209Z"
            fill="#212123" />
        <path d="M141 23H152V25H141V23Z" fill="#212123" />
        <path d="M200 23H210V25H200V23Z" fill="#212123" />
        <path d="M220 23H223V25H220V23Z" fill="#212123" />
    </svg>
</template>